import React from 'react';
import './assets/css/style.css';
import './assets/css/bootstrap.min.css';
import PdfFile from './assets/pdfs/Netgoplus_T&Cs.pdf';


function Terms() {
    const gradientColors = '#00f3ff, #00e4ff, #00d0ff, #13c1ff, #41a8ff, #549fff';
  
    const gradientStyle = {
      background: `linear-gradient(to right, ${gradientColors})`,
      color: 'white',
      borderRadius: '30px',
      padding: '10px 20px',
      textDecoration: 'none',
      display: 'inline-block'
    };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-offset-2 col-md-9">
          <div className="panel-second panel-second-big"></div>
          <div className="panel panel-big">
            <div className="back">
              <a href="/" title="Back">
                <img src={require('./assets/images/icons/back.png')} alt="Back" style={{ height: '60px' }} />
              </a>
            </div>
            <img src={require('./assets/images/terms/refntp001.jpg')} alt="Reference" />

            <div className="row">
            <div className="col-md-12 text-center">
                <address>
                  <h4>NET GO PLUS</h4>
                  By Management 
                  <a href="tel:0740025323"> +254 740025323</a>
                </address>
                <a href={PdfFile} target="_blank" rel="noopener noreferrer" className="btn btn-login" style={gradientStyle}>Download PDF</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
