import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/bootstrap.min.css';
import './assets/js/main.js';
import './assets/js/md5.js';
import Terms from './Terms';

function App() {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://business.netgoplus.com/api/v1/internet/packages')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setPackages(data.data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching the packages:', error);
        setLoading(false);
      });
  }, []);

  const handleBuyNow = (pkg) => {
    setSelectedPackage(pkg);
    setShowModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setShowModal(false);

    const processingToastId = toast.info('Processing your payment...', {
      position: "top-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      theme: "dark",
      progress: undefined,
    });

    const payload = {
      plan_id: selectedPackage.code_id,
      phone_number: phoneNumber
    };

    fetch('https://business.netgoplus.com/api/v1/payment/mpesa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Payment response:', data);
      toast.dismiss(processingToastId);
      toast.success('Payment successful!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
      });
    })
    .catch(error => {
      console.error('Error submitting payment:', error);
      toast.dismiss(processingToastId);
      // Show error notification
      toast.error('Payment failed. Please try again later.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
      });
    });
  };

  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/66a6598fbecc2fed692c2783/1i3ssrn0n';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();


  return (
    <Router>
      <div>
        <Routes>
          <Route path="/terms" element={<Terms />} />
          <Route path="/" element={
            <div className="container">
              {loading ? (
                <div className="loader" style={{ textAlign: 'center', marginTop: '50px' }}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-offset-2 col-md-9">
                    <div className="panel-second panel-second-big"></div>
                    <div className="panel panel-big">
                      <div className="back">
                        <a href="http://wifi.netgopro.net" title="Back">
                          <img src="assets/images/back.png" alt="" />
                        </a>
                      </div>
                      <div className="row">
                        {packages.map(pkg => (
                          <div className="col-md-6 text-center" key={pkg.id}>
                            <img src={pkg.image} alt={pkg.name} />
                            <div className="overlay">
                              <button className="custom-btn" onClick={() => handleBuyNow(pkg)}>Buy Now</button>
                            </div>
                            <div className="package-info" style={{ display: 'none' }}>
                              <h3>{pkg.name}</h3>
                              <p>{pkg.description}</p>
                              <p>Price: ${pkg.amount}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {showModal && (
          <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog" style={{ maxWidth: '300px' }} role="document">
              <div className="modal-content">
                <div className="modal-header">
                <header> PAYMENT    
                   <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> 
                  </header>
                  {/* <h5 className="modal-title">Buy {selectedPackage.name}</h5> */}
               
                </div>
                <div className="modal-body text-center">
                  <img src={selectedPackage.image} alt={selectedPackage.name} style={{ maxWidth: '100%', marginBottom: '20px' }} />
                  <form onSubmit={handleSubmit}>
                  <div className="tips">
                    <p>*Enter M-PESA NUMBER 07xxxxxxxx</p>
                    <p>*Click PAY and wait for STK-PUSH.</p>
                    <p>*Enter your PIN"</p>
                  </div>

                    <div className="form-group">
                      {/* <label htmlFor="phoneNumber">Phone Number</label> */}
                      <input
                        type="tel"
                        className="form-control"
                        style={{ maxWidth: '80%', margin: '0 auto' }}
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        pattern="[0-9]{10}"
                        placeholder="Enter M-PESA Number"
                        required
                      />
                    </div>
                    <button type="submit" className="btn-logout text-space"> PAY </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
        <div>
          <p className="text-center">
            <Link to="/terms" title="Download T&cs">Terms & Conditions Apply</Link> &copy; NETGOPLUS
            <script>document.write(new Date().getFullYear())</script>
          </p>
        </div>
      </div>
    </Router>
  );
}

export default App;

